import { gql } from "apollo-boost";


export const CREATE_BANNER = gql`
    mutation CreateBanner(
        $description: String!
        $image: String!
        $activeStatus: Boolean!
    ) {
        createBanner(
        description: $description
        image: $image
        activeStatus: $activeStatus
        ) {
            id
            description
            image
            activeStatus
            categories { 
                id
            }
        }
    }
`;

export const UPDATE_BANNER = gql`
    mutation updateBanner(
        $id: ID!
        $description: String!
        $image: String!
        $activeStatus: Boolean!
    ) {
        updateBanner(
        id: $id
        description: $description
        image: $image
        activeStatus: $activeStatus
        ) {
            id
            description
            image
            activeStatus
            categories { 
                id
            }
        }
    }
`;

export const ADD_IMAGE_TO_BANNER = gql`
    mutation addImageToBanner($bannerId: ID!, $imageId: ID!) {
        addImageToBanner(bannerId: $bannerId, imageId: $imageId) {
            id
            name
        }
    }
`;

export const ADD_BANNER_TO_CATEGORY = gql`
    mutation addBannerToCategory($bannerId: ID!, $categoryId: ID!) {
        addBannerToCategory(bannerId: $bannerId, categoryId: $categoryId) {
            id
        }
    }
`;

export const REMOVE_BANNER_FROM_CATEGORY = gql`
    mutation removeBannerFromCategory($bannerId: ID!, $categoryId: ID!) {
        removeBannerFromCategory(bannerId: $bannerId, categoryId: $categoryId) {
            id
        }
    }
`;

export const BANNER = gql`
    query banner($id: ID!) {
        banner(id: $id) {
            id
            description
            activeStatus
            image
        }
    }
`;


export const GET_BANNER_BY_CATEGORY = gql`
    query bannerByCategory($id: ID!) {
        bannerByCategory(id: $id) {
            id
            description
            activeStatus
            image
        }
    }
`;


export const DELETE_BANNER = gql`
    mutation deleteBanner($id: ID!) {
        deleteBanner(id: $id) {
            id
        }
    }
`;


