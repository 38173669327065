import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { BottomNavigation } from "@material-ui/core";
import MainLogo from "./MainLogo";
import { Link as RouterLink } from "react-router-dom";
import { userRoutes } from "../routes/Users/constants";
import Typography from "@material-ui/core/Typography";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import { Translate } from "react-auto-translate";


const styles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    width: "100%",

    paddingTop: 20,
    paddingBottom: 20,
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 600px)": {
      paddingTop: 10,
    paddingBottom: 10,
      height: "auto",
    }

  },
  innerContainer: {},
  text: {
    color: theme.palette.text.alt,
    textDecoration: "none"
  },
  textLeftContent:{
    display: "flex",
    justifyContent: "left",
    alignItems: "center",

    "@media (max-width: 960px)": {
     paddingLeft: 12
    }
  },
  textContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "@media (max-width: 496px)": {
      // justifyContent: "flex-start",
    }
  },
  hideOnLarge: {
    "@media (min-width: 600px)": {
      display: "none"
    }
  },
  hideOnSmall: {
    "@media (max-width: 600px)": {
      display: "none"
    }
  },  
  textLeft: {
    color: theme.palette.text.alt,
  },
  textRight: {
    color: theme.palette.text.alt,
    textAlign: "right"
  },
  socialIcon: {
    color: theme.palette.text.alt,
    backgroundColor: theme.palette.secondary.main,
    padding: 5,
    borderRadius: "50%",
    marginLeft: 5,
    marginRight: 5
  },
  hideOnMobile: {
    "@media (max-width: 956px)": {
      display: "none"
    }
  },
  iconsContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 600px)": {
      justifyContent: "space-arround",
    }
    // flexWrap: "wrap",
  },
  applogo: {
 
    "@media (min-width: 600px)": {
      textAlign: "left"
    },
    "@media (max-width: 956px)": {
      paddingLeft: "12px"
    },
    "@media (max-width: 956px) and (min-width: 600px)": {
      paddingRight: "12px"
    },

  },
}));

export default function Footer() {
  const classes = styles();

  const getCurrentYear = () => {
    const today = new Date();
    return today.getFullYear();
  };

  return (
    <BottomNavigation className={classes.container}>
      <Grid container xs={12} md={12} className={classes.innerContainer}>
      <Grid xs={false} md={1} />
        <Grid item xs={3} sm={2} md={1} component={RouterLink} to={userRoutes.SHOP} style={{textAlign: "left"}} className={`${classes.applogo} ${classes.hideOnLarge}`}>
          <MainLogo
            style={{
              height: 55
            }}
          />
        </Grid>
        <Grid item xs={3} sm={false} className={classes.hideOnLarge} />
        <Grid item xs={false} sm={8} md={4}  className={`${classes.textLeftContent} ${classes.hideOnSmall}`}>
          
         
          <MainLogo       
            style={{
              height: 55,
              position: "unset",
              paddingRight: "10px",
            }}
          />
  
    
          <Typography className={classes.textLeft}>
            ©{getCurrentYear()} zeghetmetduiten.nl
          </Typography>
        </Grid>
        <Grid item xs={false} md={3}  className={`${classes.textContent} ${classes.hideOnMobile}`}  >
          <Typography
            component={RouterLink}
            to={userRoutes.OVER}
            className={[classes.text, classes.hideOnMobile]}
          >
            <Translate>Over ons en veelgestelde vragen</Translate>
          </Typography>
        </Grid>
        <Grid item xs={6} sm={4} md={4} className={classes.iconsContent}>
          <a href="https://www.facebook.com/Zeghetmetduiten/">
            <FacebookIcon className={classes.socialIcon} />
          </a>
          <a href="https://www.linkedin.com/company/zeg-het-met-duiten/">
            <LinkedInIcon className={classes.socialIcon} />
          </a>
          <a href="https://www.instagram.com/zeghetmetduiten">
            <InstagramIcon className={classes.socialIcon} />
          </a>
        </Grid>
        <Grid item xs={12} sm={false} className={`${classes.textContent} ${classes.hideOnLarge}`}  >
          <Typography className={classes.textLeft}>
            ©{getCurrentYear()} zeghetmetduiten.nl
          </Typography>
        </Grid>
      </Grid>
    </BottomNavigation>
  );
}
