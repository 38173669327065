import React from "react";
import IconButton from "@material-ui/core/IconButton";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import SingleProductCart from "./SingleProductCart";
import DuitenIcon from "./DuitenIcon";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";
import { Translate } from "react-auto-translate";
import { userRoutes } from "../routes/Users/constants";

const headerMenu = makeStyles((theme) => ({
  buttonType: {
    color: theme.palette.text.alt,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 3,
    padding: "0px 20px",
    zIndex: 40,
    height: "40px",

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  text: {
    "@media (max-width: 768px)": {
      fontSize: 16,
    },
    "@media (max-width: 600px)": {
      // display: "none",
    },
  },
  previousContainer: {
    display: "flex",
    justifyContent: "end",
    // marginRight: "12px"
  },
  genericIcon: {
    fontSize: 30,
    cursor: "pointer",
  },

  cartIcon: {
    color: "white",
  },
  cartText: {
    fontSize: 14,
    paddingLeft: 10,
  },
  cartTotal: {
    fontSize: 20,
    fontWeight: 600,
    marginRight: "5px",
    marginBottom: "2px",
  },
  cartTotalDuiten: {
    height: 16,
  },
  cartCount: {
    position: "absolute",
    right: "-12px",
    top: "-12px",
    fontSize: 12,
    height: 18,
    width: 18,
    borderRadius: "50% 50%",
    color: "white",
    backgroundColor: theme.palette.primary.main,
  },
  popOver: {
    padding: 12,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '520px',
    },
    // overflowY: "scroll",
    // '&::-webkit-scrollbar': {
    //   width: 0,
    // },
    // scrollbarWidth: 'none',
    // '-ms-overflow-style': 'none',

  },
  hideOnSmallScreen: {
    "@media (max-width: 600px)": {
      display: "none !important",
    },
  },
  hideOnMobile: {

    "@media (min-width: 600px)": {
      display: "none !important",
    },
  },
  smallScreenTotal: {
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
    // paddingRight: "32px",
    "@media (min-width: 600px)": {
      display: "none !important",
    },
  },
  smallGenericIcon: {
    display: 'flex', justifyContent: 'right', alignItems: 'center',
    "@media (max-width: 600px)": {
      justifyContent: 'left'
    },

  }

}));

function MenuCart(state) {
  const classes = headerMenu();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  // const open = Boolean(true);
  const id = open ? "menucart-popover" : undefined;

  const history = useHistory();

  return (
    <Grid item xs={2} >
      <IconButton color="primary" onClick={handleClick} style={{ padding: 0 }}>
        <ShoppingCartIcon className={classes.genericIcon} />
        <Typography className={classes.cartCount}>
          {state.cart.length}
        </Typography>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        elevation={3}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          // style: { left:0, maxWidth: "100%",}
          style: { overflowY: state.cart.length > 10 && "scroll" }
        }}

        
      >
        <Grid container className={classes.popOver} >
          <Grid
            item
            xs={12}
            md={12}
            xl={12}
            className={classes.previousContainer}
            onClick={handleClose}
            
          >

            <Grid container style={{ padding: '5px 10px' }}>    {/* xs={12} sm={12} */}
              <Grid item xs={11} sm={11} />
              <Grid item xs={1} sm={1} className={classes.smallGenericIcon} style={{}}>
                <CloseIcon className={classes.genericIcon} />
              </Grid>
            </Grid>


          </Grid>
          <Grid item xs={12}>
            {state.cart.map((product, index) => (
              <SingleProductCart
                key={product.id}
                name={product.name}
                price={product.price}
                index={index}
                img={product.img}
              />
            ))}
          </Grid>

          <Grid item xs={3} sm={7} className={classes.hideOnSmallScreen}>
            <Button
              size="large"
              type="submit"
              className={classes.buttonType}
              onClick={() => {
                history.push(userRoutes.CART);
                handleClose();
              }}
            >
              <IconButton>
                <ShoppingCartIcon className={classes.cartIcon} />
              </IconButton>
              <Typography className={classes.text}>
                <Translate>Bekijk & bestel</Translate>
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={9} sm={4} style={{ display: "flex", alignItems: "center", marginLeft: "15px" }} className={classes.hideOnSmallScreen}>
            {state.cart.length > 0 && (
              <>
                <Typography className={classes.cartTotal} >
                  Totaal{" "}
                  {state.cart
                    .map((o) => o.price)
                    .reduce((a, c) => {
                      return a + c;
                    })}{" "}

                </Typography>
                <DuitenIcon />
              </>
            )}
            {state.cart.length <= 0 && (
              <Typography className={classes.cartText}>
                Er zit niets in je winkelmandje
              </Typography>
            )}
          </Grid>

          <Grid container className={classes.hideOnMobile} style={{ marginBottom: '4px' }}>
            <Grid item xs={9} style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
              <Typography className={classes.cartTotal} style={{ fontSize: '16px', width: 'fit-content', textAlign: 'right' }}>
                Totaal
              </Typography>
            </Grid >
            <Grid item xs={2} style={{ display: "flex", alignItems: "center" }} >
              {state.cart.length > 0 && (
                <>
                  <Typography className={classes.cartTotal} style={{ width: 'fit-content', fontSize: '16px', marginRight: 0, minWidth: '20px', paddingRight: 2 }}>

                    {state.cart
                      .map((o) => o.price)
                      .reduce((a, c) => {
                        return a + c;
                      })}{" "}

                  </Typography>
                  <DuitenIcon />
                </>
              )}
            </Grid>
            <Grid item xs={1}  >
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ display: "flex", justifyContent: "center", }} className={classes.hideOnMobile}>
            <Button
              size="large"
              type="submit"
              className={classes.buttonType}
              onClick={() => {
                history.push(userRoutes.CART);
                handleClose();
              }}
            >
              <IconButton>
                <ShoppingCartIcon className={classes.cartIcon} />
              </IconButton>
              <Typography className={classes.text}>
                <Translate>Bekijk & bestel</Translate>
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  const { cart } = state;
  return { cart };
};

export default connect(mapStateToProps)(MenuCart);
