import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { setLanguage } from "../actions";
import nl_logo from "../assets/nl.png";
import gb_logo from "../assets/gb.png";

const styles = makeStyles(theme => ({
  container: {
    // position: "absolute",
    // right: 20,
    // top: 0
  },
  flag: {
    width: '40px',
    cursor: 'pointer',

    "@media (max-width: 436px)": {
      // width: '28px',
      marginLeft: "10px"
    }
  }
}));

function TranslationFlag(props) {
  const classes = styles();
  console.log(props.language, "props.language")

  if (props.language === "en") {
    return (
      <div
        className={classes.container}
        onClick={() => props.setLanguage("nl")}
      >
        <img className={classes.flag} alt="NL" src={nl_logo} style={props.style} />
      </div>
    );
  } else {
    return (
      <div
        className={classes.container}
        onClick={() => props.setLanguage("en")}
      >
        <img className={classes.flag} alt="GB" src={gb_logo} style={props.style} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { language } = state;
  return { language };
};

const mapDispatchToProps = dispatch => {
  return {
    setLanguage: language => {
      dispatch(setLanguage(language));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TranslationFlag);
