import React from "react";
import Logo from "../assets/Logo.svg";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  mainLogo: {
    height: 55,
    "@media (min-width: 769px)": {
      position: "absolute",
    },
  },
}));

export default function MainLogo(props) {
  const classes = useStyles();

  return <img alt="Zeg het met Duiten" src={Logo} className={classes.mainLogo} style={props.style} />;
}
