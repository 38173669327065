import React from "react";
import Grid from "@material-ui/core/Grid";
import DuitenIcon from "./DuitenIcon";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import { removeFromCart } from "../actions";
import { Typography } from "@material-ui/core";
import srcSetCreator from "../helpers/srcSetCreator";
import LazyLoad from "react-lazy-load";
import { Translate } from "react-auto-translate";

const styles = makeStyles(theme => ({
  container: {
    padding: "5px 10px ",
    "@media (max-width: 500px)": {
      padding: "5px 0 ",
    },

    minWidth: 260,
    maxWidth: "100%",
    [theme.breakpoints.up('sm')]: {
      minWidth: 400,
    },
  },
  price: {
    marginRight: 5,
    marginBottom: 2,
    minWidth: "20px",
    textAlign: "right",
  },
  productname: {
    paddingLeft: 10,
    wordWrap: "break-word",
  },
  iconButton:{
  "@media (max-width: 600px)": {
    padding: 0
  }
}
}));

function SingleProductCart(props) {
  const classes = styles();

  const handleClick = () => {
    console.log("handle click")
    props.onRemoveClick(props.index);
  };

  return (
    <Grid container className={classes.container} alignItems="center">
      <Grid xs={2} sm={1}>
        <LazyLoad>
          {/* <img
            src={process.env.PUBLIC_URL + "/images/" + props.img}
            style={{ maxHeight: 40, maxWidth: 40 }}
            alt={props.name}
            srcSet={props.newImg ? srcSetCreator(props.img) : ""}
          /> */}
          
          <img
            src={"https://shop.zeghetmetduiten.nl/images/" + props.img}
            style={{ maxHeight: 40, maxWidth: 40 }}
            alt={props.name}
            srcSet={props.newImg ? srcSetCreator(props.img) : ""}
          />
        </LazyLoad>
      </Grid>
      <Grid xs={7} sm={8}>
        <Typography className={classes.productname}>
          {props.name}
        </Typography>
        {props.stock && (
          <>
          <br />
          <span style={{ color: 'red', fontWeight: 'bold' }}><Translate >Niet op voorraad</Translate></span>
          </>
        )}

      </Grid>
      <Grid xs={2} sm={2} container style={{display: "flex", alignItems: "center"  }}>
        <Typography className={classes.price}>{props.price}</Typography>
        <DuitenIcon />
      </Grid>
      <Grid xs={1} sm={1}>
        <IconButton color="primary" className={classes.iconButton} onClick={handleClick}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    onRemoveClick: index => {
      dispatch(removeFromCart(index));
    }
  };
};
export default connect(
  null,
  mapDispatchToProps
)(SingleProductCart);
