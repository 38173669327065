import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { categories } from "../queries-mutations/products";
import Grid from "@material-ui/core/Grid";
import SingleCategorySmall from "./SingleCategorySmall";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Translate } from "react-auto-translate";
import { useMediaQuery } from "@material-ui/core";

const styles = makeStyles(theme => ({
  text: {
    color: theme.palette.text.main,
    textAlign: "left",
    marginLeft: 20,
    fontSize: 30
  },
  container: { marginTop: 30 ,   marginBottom: 30,}
}));

export default function MainCategoryOverview() {
  const classes = styles();

  const theme = useTheme();
  const isMdUp =  useMediaQuery(theme.breakpoints.up('md'));
  const { loading, error, data } = useQuery(categories);

  // data.categories = data.categories.filter(category => {
  //   return category.published;
  // })

  if (data) {
    return (
      <Grid container className={classes.container} spacing={isMdUp ? 8 : 0} >
        {/* <Grid xs={12}>
          <h2 className={classes.text}>
            <Translate>Kramen</Translate>
          </h2>
        </Grid> */}
        {data.categories.filter(category => {
           return category.published;
          }).map(category => (
        <Grid item xs={6} sm={4} md={3} key={category.id} className={classes.item} style={isMdUp ? {}:{padding: '15px 0'}}>
          <SingleCategorySmall
            alt={category.name}
            newImg={category.image && category.image.new}
            img={category.image && category.image.location}
            id={category.id}
          />
            </Grid>
        ))}
        
      </Grid>
    );
  } else if (loading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  } else if (error) {
    return (
      <div>
        <h2>
          <Translate>Er ging iets fout</Translate>
        </h2>
      </div>
    );
  }
}
