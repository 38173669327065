import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import ProductenTable from "../../components/ProductenTable";
import EditProductModal from "../../components/EditProductModal";
import AddNewButton from "../../components/AddNewButton";
import { useQuery } from "@apollo/react-hooks";
import { ADMIN_OVERVIEW_PRODUCTS } from "../../queries-mutations/products";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function Producten() {
  const [modal, setModal] = React.useState();

  const { loading, error, data , refetch } = useQuery(ADMIN_OVERVIEW_PRODUCTS, {
    returnPartialData: false , errorPolicy: "ignore" 
  },{ errorPolicy: "all" });

  const handleClose = () => {
    setModal(<div />);
    setTimeout(function() {
      refetch();
    }, 500);
  };

  console.log(data, "products");

  useEffect(() => {
    console.log(data, "useEffect - Refetch changed");
    
    // Check if data and products are defined
    if (!data || !data.products || !data.products.length || !data.products[0] || !data.products[0].categories) {
      console.log("No products data, refetching...");
      refetch().then(() => console.log("Refetch complete")).catch(err => console.error("Refetch error", err));
    } else {
      console.log("Data is available");
    }
  }, [data, refetch]);
  

  const onRowClick = clickedProduct => {
    setModal(
      <EditProductModal
        open={true}
        onClose={handleClose}
        product={clickedProduct}
      />
    );
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {loading && <CircularProgress color="primary" />}
        {error && <p>{error.message}</p>}
        {data && (
          <ProductenTable
            title="Producten (gepubliceerd)"
            onRowClick={onRowClick}
            data={data.products.filter(product => {
              return product.published;
            })}
          />
        )}
        <br />
        <br />
        {data && (
          <ProductenTable
            title="Producten (niet gepubliceerd)"
            onRowClick={onRowClick}
            data={data.products.filter(product => {
              return !product.published;
            })}
          />
        )}
        <AddNewButton onClick={onRowClick} />
        {modal}
      </Grid>
    </Grid>
  );
}
