import React, { useEffect, useRef, useState } from "react";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import { Editor } from "@tinymce/tinymce-react";
import { tinyKey } from "../Keys/tiny";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CategoriesSelector from "./CategoriesSelector";
import objectInArray from "../helpers/objectInArray";
import { useMutation } from "@apollo/react-hooks";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {
  ADD_PRODUCT_TO_CATEGORY,
  CREATE_PRODUCT,
  REMOVE_PRODUCT_FROM_CATEGORY,
  UPDATE_PRODUCT,
  CREATE_IMAGE,
  ADD_IMAGE_TO_PRODUCT,
  REMOVE_IMAGE_FROM_PRODUCT,
  DELETE_PRODUCT,
} from "../queries-mutations/products";
import { editModalStyle } from "../styles/editModalStyle";
import ImageUploader from "react-images-upload";
import ImageUpload from "../helpers/ImageUpload";
import AdminImageDisplay from "./AdminImageDisplay";
import { trackPromise } from "react-promise-tracker";
import ConfirmationRequiredButton from "./ConfirmationRequiredButton";
import { PinDropRounded } from "@material-ui/icons";
import ReactCrop from "react-image-crop";
import { Translate } from "react-auto-translate/lib/commonjs";

export default function EditProductModal(props) {
  const [state, setState] = useState({
    name: props.product.name ? props.product.name : "",
    description: props.product.description ? props.product.description : "",
    price: props.product.price ? props.product.price : 0,
    published: !!props.product.published,
    code: props.product.code ? props.product.code : "",
    taxpercent : props.product.taxpercent ? props.product.taxpercent.toString() : "",
    order: props.product.order ? props.product.order : "",
    selectedCategories: props.product.categories
      ? props.product.categories
      : [],
    addedCategories: [],
    removedCategories: [],
    stock: props.product.stockAmount ? props.product.stockAmount : 0,
    images: [],
    removedImages: [],
    existingImages: props.product.images ? props.product.images : [],
    cartSuggestion: props.product.cartSuggestion
      ? props.product.cartSuggestion
      : false,
    featured: props.product.featured ? props.product.featured : false,
  });

  const classes = editModalStyle();

  const [updateProduct] = useMutation(UPDATE_PRODUCT);
  const [createProduct] = useMutation(CREATE_PRODUCT);
  const [addProductToCategory] = useMutation(ADD_PRODUCT_TO_CATEGORY);
  const [removeProductFromCategory] = useMutation(REMOVE_PRODUCT_FROM_CATEGORY);
  const [createImage] = useMutation(CREATE_IMAGE);
  const [addImageToProduct] = useMutation(ADD_IMAGE_TO_PRODUCT);
  const [removeImageFromProduct] = useMutation(REMOVE_IMAGE_FROM_PRODUCT);
  const [deleteProduct] = useMutation(DELETE_PRODUCT);

   // cropper
  const [isPreview, setIsPreview] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [errors, setErrors] = useState(false);
  const imgRef = useRef(null);
  const [isCropper, setIsCropper] = useState(false);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [crop, setCrop] = useState({ unit: 'px',width: 750,height: 750, x: 0, y: 0 });

  let title = props.product.id ? "Product aanpassen" : "Nieuw product maken";

  const handleCheckChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handletaxChange = event => {
    setState({ ...state, [event.target.name]: event.target.value });
    console.log(state);
  };

  const handleTextChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleDescriptionChange = (content, editor) => {
    setState({ ...state, description: content });
  };

  const onDropImage = (image) => {
    // setState({ ...state, images: image });
    try {
      console.log(image, "image");
      if (!image[0]) return;

      const file = image[image.length - 1];
      const objectUrl = URL.createObjectURL(file);
      const img = new Image();

      img.src = objectUrl;

      img.onload = () => {
        console.log(img.width, img.height, "image");
        if (img.width >= 750 && img.height >= 750) {
          console.log(objectUrl, "objectUrl");
          
          setIsPreview(true); // Enable preview
          setPreviewUrl(objectUrl); // Set preview URL
          setErrors(false);
          image.splice(0, 1);
          setIsCropper(true);
        } else {
          setIsPreview(false); // Disable preview
          setPreviewUrl(""); // Clear preview URL
          setErrors(true);
          image.splice(0, 1);
        }
        URL.revokeObjectURL(objectUrl); // Clean up
      };

      img.onerror = (error) => {
        console.log(error, "error");
        setIsPreview(false); // Disable preview
        setPreviewUrl(""); // Clear preview URL
        setErrors(true); // Show error for image loading issue
      };
   


    } catch (err) {
      console.log(err, "error");
    }
  };

  const removeImage = (imageId) => {
    setState({ ...state, removedImages: [...state.removedImages, imageId] });
  };

  const removeStateImage = (imageId) => {
    const updatedImages = state.images.filter(image => image.id !== imageId);

    setState({ ...state, images: updatedImages });
  };

  const onSelectCategory = (data, context) => {
    setState({ ...state, selectedCategories: data });
    setState({
      ...state,
      addedCategories: [...state.addedCategories, context],
    });
  };

  const onRemoveCategory = (data, context) => {
    setState({ ...state, selectedCategories: data });
    setState({
      ...state,
      removedCategories: [...state.removedCategories, context],
    });
  };
  const [initiatedDelete, setInitiatedDelete] = useState(false);

  const initiateDelete = () => {
    setInitiatedDelete(true);
  };

  const cancelDelete = () => {
    setInitiatedDelete(false);
  };

  const confirmDelete = () => {
    deleteProduct({ variables: { id: props.product.id } });
    props.onClose();
  };

  const saveProduct = async () => {
    let productID = "";
  
    // Remove categories that are already added
    state.removedCategories.forEach((removedCategory, removedIndex) => {
      if (objectInArray(removedCategory, state.addedCategories) >= 0) {
        const addedIndex = objectInArray(removedCategory, state.addedCategories);
        setState({
          ...state,
          removedCategories: [
            ...state.removedCategories.slice(0, removedIndex),
            ...state.removedCategories.slice(removedIndex + 1),
          ],
          addedCategories: [
            ...state.addedCategories.slice(0, addedIndex),
            ...state.addedCategories.slice(addedIndex + 1),
          ],
        });
      }
    });
  
    try {
      if (!!props.product.id) {
        // Update product if it already exists
        productID = props.product.id;
        console.log(productID, "productID");
  
        await updateProduct({
          variables: {
            id: productID,
            name: state.name,
            description: state.description,
            price: parseInt(state.price),
            published: state.published,
            code: state.code,
            taxpercent: parseInt(state.taxpercent),
            order: parseInt(state.order),
            stockAmount: parseInt(state.stock),
            cartSuggestion: state.cartSuggestion,
            featured: state.featured,
          },
        });
  
        await Promise.all(state.removedCategories.map((removedCategory) =>
          removeProductFromCategory({
            variables: {
              productId: productID,
              categoryId: removedCategory.id,
            },
          })
        ));
  
        await Promise.all(state.addedCategories.map((addedCategory) =>
          addProductToCategory({
            variables: {
              productId: productID,
              categoryId: addedCategory.id,
            },
          })
        ));
      } else {
        // Create product if it does not exist
        console.log("Creating new product");
  
        const createResponse = await createProduct({
          variables: {
            name: state.name,
            description: state.description,
            price: parseInt(state.price),
            authorID: "ckbjguxgm000x0706su23aqjc", // TODO: Replace with actual user ID
            published: state.published,
            code: state.code,
            taxpercent: parseInt(state.taxpercent),
            order: parseInt(state.order),
            stockAmount: parseInt(state.stock),
            cartSuggestion: state.cartSuggestion,
            featured: state.featured,
          },
        });
  
        productID = createResponse.data.createProduct.id;
        console.log(productID, "productID");
  
        await Promise.all(state.addedCategories.map((addedCategory) =>
          addProductToCategory({
            variables: {
              productId: productID,
              categoryId: addedCategory.id,
            },
          })
        ));
  
        await Promise.all(state.removedCategories.map((removedCategory) =>
          removeProductFromCategory({
            variables: {
              productId: productID,
              categoryId: removedCategory.id,
            },
          })
        ));
      }
  
      // Add images to product
      console.log(productID, "productID");
  
      const imageUploadPromises = state.images.map((image) =>
        createImage({
          variables: {
            location: image.location,
          },
        }).then((r) => {
          console.log(r.data.createImage.id, "image upload");
          return addImageToProduct({
            variables: {
              productId: productID,
              imageId: r.data.createImage.id,
            },
          });
        })
      );
  
      await Promise.all(imageUploadPromises);
  
      // Remove images
      const imageRemovalPromises = state.removedImages.map((image) =>
        removeImageFromProduct({
          variables: {
            productId: productID,
            imageId: image,
          },
        })
      );
  
      await Promise.all(imageRemovalPromises);
  
      props.onClose();
    } catch (err) {
      console.log(err);
    }
  };
  
  const handleCropComplete = async () => {
    console.log(imgRef.current, crop.width, crop.height, "handlecrop")
    console.log(state.existingImages, "existingImages")
    setCompletedCrop(crop);

    if (imgRef.current && crop.width && crop.height) {
      console.log("true")
      const canvas = document.createElement('canvas');
      const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
      const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(
        imgRef.current,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      canvas.toBlob(async (blob) => {
        console.log(blob, "blob");
        if (blob) {
          try {
          // Upload the cropped image
          ImageUpload(blob, true).then((r) => {
            
            let temp = {
              id: state.images.length + 1,
              location: r.data.name
            }
           
             // Use functional update to get the latest state
  setState((prevState) => ({
    ...prevState,
    images: [...prevState.images, temp]  // update the images array correctly
  }));

            console.log(state )
            setIsCropper(false)
          })
        

            console.log('Image uploaded successfully');
          } catch (error) {
            console.error('Error uploading image:', error);
          }
        }
      }, 'image/jpeg');
    }
    console.log("end")
  };



  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.onClose}
        className={classes.parent}
      >
        <Paper className={classes.prodmodalStyle}>

        {isCropper ? (
            <>
              
              <ReactCrop
                crop={crop}
                onChange={(newCrop) => setCrop(newCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                ruleOfThirds
                minWidth={750}
                minHeight={750}
                maxWidth={750}
                maxHeight={750}
                keepSelection
                disabled={false} // Allow movement but not resizing
              >
                <img ref={imgRef} src={previewUrl} alt="Crop preview" style={{ width: '100%' }} />
              </ReactCrop>

              <Button
                  variant="contained"
                  color="secondary"
                  className={classes.input}
                  onClick={() => setIsCropper(false)}                             
                  type="button"
                  style={{ marginRight: 20 }} 
                >
                  <Translate>annuleren</Translate>
                  
                </Button>
              <Button
                  variant="contained"
                  color="primary"
                  className={classes.input}
                  onClick={handleCropComplete}
                  type="button"
                >
                  <Translate>Gewas</Translate>                  
                </Button>
            </>
          ) : (
            <>
          <h2>{title}</h2>
          <form 
            onSubmit={(event) => {
              event.preventDefault();
              saveProduct();
            }}
          >
         
            <TextField
              variant="outlined"
              label="Naam"
              name="name"
              required
              defaultValue={state.name}
              className={classes.input}
              onChange={handleTextChange}
            />
            <br />
            <TextField
              variant="outlined"
              label="Code"
              name="code"
              required
              defaultValue={state.code}
              className={classes.input}
              onChange={handleTextChange}
              inputProps={{ min: 0 }}
            />
            <br />
            <TextField
              required
              variant="outlined"
              label="Prijs"
              name="price"
              type="number"
              defaultValue={state.price}
              className={classes.input}
              onChange={handleTextChange}
              inputProps={{ min: 0 }}
            />
            <br />
            <TextField
              required
              variant="outlined"
              label="Op voorraad"
              name="stock"
              type="number"
              defaultValue={state.stock}
              className={[classes.input, classes.lastInput]}
              onChange={handleTextChange}
              inputProps={{ min: 0 }}
            />
            <br />
            <FormControl className={classes.input}>
              <FormLabel id="demo-controlled-radio-buttons-group">
                Tax percentage
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="taxpercent"
                required
                value={state.taxpercent}
                onChange={handletaxChange}
              >
                <FormControlLabel
                  value="9"
                  control={<Radio required={true}/>}
                  label="9%"
                />
                <FormControlLabel
                  value="21"
                  control={<Radio required={true}/>}
                  label="21%"
                />
              </RadioGroup>
            </FormControl>
            <br />
            <TextField
              required
              variant="outlined"
              label="sorteervolgorde"
              name="order"
              type="number"
              defaultValue={state.order}
              className={[classes.input, classes.lastInput]}
              onChange={handleTextChange}
              inputProps={{ min: 0 }}
            />
            <br />
            <FormControl className={classes.input}>
            <FormLabel
             className={classes.lastInput}
             id="demo-controlled-radio-buttons-group">
                categorieën
              </FormLabel>
            <CategoriesSelector
              onSelectCategory={onSelectCategory}
              onRemoveCategory={onRemoveCategory}
              selectedCategories={state.selectedCategories}
            />
            </FormControl>
            <br />
            <FormControlLabel
              className={[classes.input, classes.lastInput]}
              control={
                <Switch
                  checked={state.published}
                  onChange={handleCheckChange}
                  name="published"
                  label="Gepubliceerd"
                />
              }
              label="Gepubliceerd"
            />
            <FormControlLabel
              className={[classes.input, classes.lastInput]}
              control={
                <Switch
                  checked={state.featured}
                  onChange={handleCheckChange}
                  name="featured"
                  label="Uitgelicht"
                />
              }
              label="Uitgelicht"
            />
            <FormControlLabel
              className={[classes.input, classes.lastInput]}
              control={
                <Switch
                  checked={state.cartSuggestion}
                  onChange={handleCheckChange}
                  name="cartSuggestion"
                  label="Winkelwagen suggestie"
                />
              }
              label="Winkelwagen suggestie"
            />
            <Editor
              apiKey={tinyKey}
              value={state.description}
              init={{
                height: 250,
                marginTop: 10,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  // eslint-disable-next-line no-multi-str
                  "undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help",
              }}
              onEditorChange={handleDescriptionChange}
            />
            <AdminImageDisplay
              images={state.existingImages}
              removeImage={removeImage}
            />
             {/* <AdminImageDisplay
              images={state.image}
              removeImage={removeImage}
            /> */}

{isPreview && state.images.length > 0 && ( 
  <>
    <AdminImageDisplay images={state.images} removeImage={removeStateImage}/>
  </>
)}
            <ImageUploader
              withIcon={true}
              onChange={onDropImage}
              imgExtension={[".jpg", ".png"]}
              maxFileSize={10024 * 1024 * 1024}
              withPreview={false}
            />
              {errors && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    Selected image does not meet the required resolution (min 750x750).
                  </span>
                )}
            {props.product && (
              <ConfirmationRequiredButton
                initiated={initiatedDelete}
                cancel={cancelDelete}
                initiate={initiateDelete}
                confirm={confirmDelete}
                confirmationMessage={
                  "Weet je zeker dat je dit product wilt verwijderen? Alle gerelateerde informatie (inclusief bestellingen) word ook verwijderd. Dit is ten zeerste afgeraden, niet publiceren is vrijwel altijd een betere optie"
                }
                cancelMessage={"Annuleren"}
                confirmMessage={"Verwijderen"}
                initialMessage={"Verwijderen"}
              />
            )}
            <Button
              variant="contained"
              color="primary"
              className={classes.input}
              type="submit"
            >
              Opslaan
            </Button>
          </form>
        </>
      )}
        </Paper>
      </Modal>
    </div>
  );
}
