import React from "react";
import Grid from "@material-ui/core/Grid";
import LazyLoad from "react-lazy-load";
import srcSetCreator from "../helpers/srcSetCreator";
import { Typography } from "@material-ui/core";
import DuitenIcon from "./DuitenIcon";
import { userRoutes } from "../routes/Users/constants";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Translate } from "react-auto-translate";

const styles = makeStyles(theme => ({
  text: {
    color: "black"
  },
  searchContainer:{
    paddingBottom: 12
  }
}));

export default function SingleSearchResult(props) {
  const classes = styles();

  let image = props.product.images[0] ? props.product.images[0] : {};

  return (
    <Link
      to={userRoutes.PRODUCT + props.product.id}
      style={{
        textDecoration: "none",
        
      }}
    >
      <Grid container alignItems="center" className={classes.searchContainer}>
        <Grid xs={2}>
          <LazyLoad>
            <img
              src={process.env.PUBLIC_URL + "/images/" + image.location}
              style={{ maxHeight: 40, maxWidth: 40 }}
              alt={props.product.name}
              srcSet={image.new ? srcSetCreator(image.location) : ""}
            />
          </LazyLoad>
        </Grid>
        <Grid xs={7}>
          <Typography className={classes.text}>
            <Translate>{props.product.name}</Translate>
          </Typography>
        </Grid>
        <Grid xs={3} container alignItems="center" style={{ justifyContent: 'end'}}>
          <Typography className={classes.text} style={{     marginRight: '5px',    marginBottom: '2px' }}>
            {props.product.price}
          </Typography>
          <DuitenIcon />
        </Grid>
      </Grid>
    </Link>
  );
}
