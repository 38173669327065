import React from "react";
import MUIDataTable from "mui-datatables";
import srcSetCreator from "../helpers/srcSetCreator";
import { Switch } from "@material-ui/core";
import { categories } from "../queries-mutations/products";

export default function BannersTable(props) {
  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false
      }
    },
    {
      name: "sno",
      label: "S.No",
      options: {
        display: true
      }
    },
    {
      name: "image",
      label: "Image",
      options: {
        filterType: "textField",
        customBodyRender: (value) => {
          const srcSet = srcSetCreator(value);
          return (
            <img 
              src={srcSet.split(",")[0].split(" ")[0]} 
              srcSet={srcSet} 
              alt="Banner" 
              style={{ width: 100, height: 'auto' }} 
            />
          );
        }
      }
    },
    {
      name: "description",
      label: "Beschrijving",
      options: {
        filter: false,
        customBodyRender: (value) => {
          return (
            <div
              dangerouslySetInnerHTML={{ __html: value }} // Renders rich text
            />
          );
        }
      }
    },
    {
      name: "activeStatus",
      label: "ActiveStatus",
      options: {
        filterType: "textField",
        customBodyRender: (value) => {
          return (
            <Switch
              checked={value}
              color="primary"
              inputProps={{ "aria-label": "controlled" }}
            />
          );
        },
      }
    }
  ];

  let tableData = [];

  props.data.forEach(function(banner, index) {
    console.log({banner});
    tableData.push({
      id: banner.id,
      sno: index + 1,
      description: banner.description,
      image: banner.image ? banner.image : "",
      activeStatus: banner.activeStatus,
      categories: banner.categories
    });
  });

  const onRowClick = selected => {
    const selectedBanner = props.data.find(
      banner => banner.id === selected[0]
    );
    let selectedCategories = [];
    try {
      selectedBanner.categories.forEach(category => {
        category = props.categories.filter(cat => cat.id === category.id);
        selectedCategories.push(category[0]);
      })
      selectedBanner.categories = selectedCategories
    } catch (error) {}
    props.onRowClick(selectedBanner);
  };

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    onRowClick: onRowClick
  };

  return (
    <MUIDataTable
      title={props.title}
      data={tableData}
      columns={columns}
      options={options}
    />
  );
}
