export default function srcSetCreator(src) {
  let realSrc = "https://shop.zeghetmetduiten.nl/images/" + src.slice(0, -4);
  const png = ".png";

  let xs = realSrc + "_xs" + png + " 50w";
  let s = realSrc + "_s" + png + " 300w";
  let m = realSrc + "_m" + png + " 500w";
  let l = realSrc + "_l" + png + " 800w";
  let xl = realSrc + "_xl" + png + " 1200w";
  let srcSet = xs + "," + s + "," + m + "," + l + "," + xl;

  return srcSet;
}
