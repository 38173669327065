import React from "react";
import Grid from "@material-ui/core/Grid";
import LazyLoad from "react-lazy-load";
import srcSetCreator from "../helpers/srcSetCreator";
import { Typography } from "@material-ui/core";
import DuitenIcon from "./DuitenIcon";
import { makeStyles } from "@material-ui/core/styles";
import { Translate } from "react-auto-translate";

const styles = makeStyles((theme) => ({
  container: {
    padding: "5px 10px",
    minWidth: 400,
    maxWidth: "100%",
  },
  price: {
    marginRight: 5,
    marginBottom: 2,
  },
}));

export default function SingleProductOrderOverview(props) {
  const classes = styles();
  console.log(props);
  return (
    <Grid container className={classes.container} alignItems="center">
      <Grid xs={3} item>
        <LazyLoad>
          {/* <img
            src={process.env.PUBLIC_URL + "/images/" + props.img}
            style={{ maxHeight: 40, maxWidth: 40 }}
            alt={props.name}
            srcSet={props.newImg ? srcSetCreator(props.img) : ""}
          /> */}

          <img
            src={"https://shop.zeghetmetduiten.nl/images/" + props.img}
            style={{ maxHeight: 40, maxWidth: 40 }}
            alt={props.name}
            srcSet={props.newImg ? srcSetCreator(props.img) : ""}
          />
        </LazyLoad>
      </Grid>
      <Grid xs={7} item>
        <Translate>{props.name}</Translate>
      </Grid>
      <Grid item xs={2} container alignItems="center">
        <Typography className={classes.price}>{props.price}</Typography>
        <DuitenIcon />
      </Grid>
    </Grid>
  );
}
