import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { HeaderTextStyles } from "../styles/UsersGeneral";
import { Translate } from "react-auto-translate";

export default function HeaderText() {
  const classes = HeaderTextStyles();

  return (
    <Grid container justify-content="center" className={classes.hideOnMobile}>
      {/* <Grid item xs={2}>
        <Typography className={classes.Text}>
          Zeg het met <b>duiten</b>
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography className={classes.Text}>
          <Translate>Geschenkmarkten voor </Translate>
          <b>
            <Translate> bedrijven</Translate>
          </b>
        </Typography>
      </Grid> */}
    </Grid>
  );
}
