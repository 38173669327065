import React from "react";
import Grid from "@material-ui/core/Grid";
import AddNewButton from "../../components/AddNewButton";
import { useQuery } from "@apollo/react-hooks";
import { ADMIN_OVERVIEW_BANNERS, GET_CATEGORIES } from "../../queries-mutations/products";
import CircularProgress from "@material-ui/core/CircularProgress";
import BannersTable from "../../components/BannersTable";
import EditBannerModal from "../../components/EditBannerModal";

export default function Banners() {
    const [modal, setModal] = React.useState();
    
    const { loading: loading, error: error, data: data, refetch: refetch } = useQuery(ADMIN_OVERVIEW_BANNERS, {
        returnPartialData: false, errorPolicy: "ignore"
    }, { errorPolicy: "all" });

    const { loading: categoriesLoading, error: categoriesError, data: categoriesData, refetch: refetchCategories } = useQuery(GET_CATEGORIES, {
        returnPartialData: false,
        errorPolicy: "all",
    });

    const handleClose = () => {
        setModal(<div />);
        setTimeout(function () {
            refetch();
            refetchCategories();
        }, 500);
    };

    const onRowClick = clickedBanner => {
        setModal(
            <EditBannerModal
                open={true}
                onClose={handleClose}
                banner={clickedBanner}
                categories={clickedBanner}
            />
        );
    };
    if (!loading && !categoriesLoading) {
        console.log(data.banners, "banners");
        console.log(categoriesData, "categories");
    }
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                {loading && <CircularProgress color="primary" />}
                {error && <p>{error.message}</p>}
                {data && categoriesData && (
                    <BannersTable
                        title="Banners (gepubliceerd)"
                        onRowClick={onRowClick}
                        data={data.banners}
                        categories={categoriesData.categories}
                    />
                )}
                <br />
                <AddNewButton onClick={onRowClick} />
                {modal}
                
            </Grid>
        </Grid>
    );
}
