import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";
import { userRoutes } from "../routes/Users/constants";
import LazyLoad from "react-lazy-load";
import srcSetCreator from "../helpers/srcSetCreator";

const styles = makeStyles(theme => ({
  img: {
    maxWidth: "100%", // Default for screens smaller than 600px

    // "@media (max-width: 600px)": {
    //   maxWidth: "50%", // For screens 600px and larger
    // },

    "@media (max-width: 958px)": {
      maxWidth: "75%", // For screens 765px and larger
    },
  },
  container: {
  
    padding: 0,


  }
}));

export default function SingleCategorySmall(props) {
  const classes = styles();
  return (
    // 
    // <Grid xs={6} sm={4} md={3}  className={classes.container}>
      <RouterLink to={userRoutes.CATEGORY + props.id}>
        <LazyLoad offset={500}>
          <img
            src={"https://shop.zeghetmetduiten.nl/images/" + props.img}
            srcSet={props.newImg ? srcSetCreator(props.img) : ""}
            alt={props.name}
            className={classes.img}
          />
        </LazyLoad>
      </RouterLink>
    // </Grid>
  );
}
