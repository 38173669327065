import axios from "axios";

export default function ImageUpload(image ,isBlob = false) {
  const imageData = new FormData();
  if(isBlob){
    imageData.append("image",  image, 'cropped-image.jpg');
  }else{
    imageData.append("image", image);
  }
 

  return axios.post(
    "https://shop.zeghetmetduiten.nl:4001/upload/post",
    // "http://localhost:4001/upload/post",
    // "http://103.108.220.133:4001/uploads/post",
    imageData,
    {
      headers: {
        enctype: "multipart/form-data",
        "Content-type": "multipart/form-data"
      } // receive two parameter endpoint url ,form data
    }
  );
}
