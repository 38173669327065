import { useHistory } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  previous: {
    textAlign: "Left",
    padding: 4,
    border: "1px solid #F0F0F0",
    width: 70,
    fontSize: 14,
    borderRadius: 4,
    display: "flex",     
    alignItems: "center",
    cursor: "pointer",
    backgroundColor: "#F0F0F0",
    "&:hover": {
        backgroundColor: "#f2994a",
        color: "white", 
         border: "1px solid #f2994a"
      },

  },
  backIcon: {
    fontSize: 14,
  },
}));


export default function CategoryBack({ back = () => {} }) {
  const classes = styles();

  const history = useHistory();



  return (
    <Typography className={classes.previous} onClick={back}>
      <ArrowBackIosIcon className={classes.backIcon} />
      Ga terug
    </Typography>
  );
}
