import { createTheme } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: "#0d7c5f"
    },
    secondary: {
      main: "#f2994a"
    },
    error: {
      main: red.A400
    },
    background: {
      default: "#f3f3f3"
    },
    text: {
      main: "#333333",
      alt: "#ffffff"
    }
  }
});

export default theme;
